<template>
  <Stack aria-label="Breadcrumb" tag="nav" justify="between" align="center">
    <Stack tag="ol" align="start" direction="col" gap="none" :breakpoints="{ direction: { md: 'row' } }">
      <Stack
        tag="li"
        gap="2xs"
        role="listitem"
        direction="col"
        justify="start"
        align="start"
        :breakpoints="{ direction: { md: 'row' } }"
        class="breadcrumbs"
        :class="isExpanded && 'expanded'"
      >
        <ul class="flex flex-col md:flex-row">
          <li v-for="(item, index) in breadcrumbItems" :key="item.title">
            <BreadcrumbItem
              :title="item.title"
              :disabled="item.disabled"
              v-if="index !== breadcrumbItems.length - 1 && index !== breadcrumbItems.length - 2"
              :href="item.href"
            >
              <Icon name="nav-left" :size="10" />
            </BreadcrumbItem>
          </li>
        </ul>
      </Stack>

      <Stack
        tag="li"
        gap="none"
        role="listitem"
        direction="col"
        justify="start"
        align="start"
        :breakpoints="{ direction: { md: 'row' } }"
      >
        <ul class="flex flex-col md:flex-row">
          <li v-for="(item, index) in breadcrumbItems" :key="item.title">
            <BreadcrumbItem
              :title="item.title"
              :disabled="item.disabled"
              :href="item.href"
              v-if="index === breadcrumbItems.length - 1 || index === breadcrumbItems.length - 2"
            >
              <Icon name="nav-left" :size="10" />
            </BreadcrumbItem>
          </li>
        </ul>
      </Stack>
    </Stack>

    <button
      class="md:hidden bg-grey-light text-mkm-blue-light p-[11px] rounded-sm"
      @click="isExpanded = !isExpanded"
      v-if="breadcrumbItems.length > 2"
    >
      <span class="block transition-all duration-100" :class="isExpanded ? 'rotate-180' : 'rotate-0'">
        <span class="sr-only">Open Breadcrumbs</span>
        <Icon name="nav-down" :size="10" />
      </span>
    </button>
  </Stack>
</template>

<script lang="ts" setup>
import type { BreadcrumbItemProps, BreadcrumbsProps } from "./breadcrumbTypes";
import { computed, ref } from "vue";
import BreadcrumbItem from "./BreadcrumbItem.vue";

const props = defineProps<BreadcrumbsProps>();
const isExpanded = ref<boolean>(false);

const ensureLastItemIsDisabled = (items: BreadcrumbItemProps[]): BreadcrumbItemProps[] => {
  return items.map((item, index) => {
    if (index === items.length - 1) {
      return { ...item, disabled: true };
    }
    return item;
  });
};

const ensureHomePagePresence = (items: BreadcrumbItemProps[]): BreadcrumbItemProps[] => {
  const itemsWithoutHome = items.filter((item) => item.title !== "Home");

  return [{ title: "Home", href: "/" }, ...itemsWithoutHome];
};

const breadcrumbItems = computed((): BreadcrumbItemProps[] => {
  const items = [...props.items];

  return ensureLastItemIsDisabled(ensureHomePagePresence(items));
});
</script>
